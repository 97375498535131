<template>
  <div class="container-page">
    <p class="title-inner">Таможенные пошлины</p>

    <div class="mb-16 d-flex flex-row justify-content-between">
      <div class="d-flex flex-row">
        <ButtonStock class="mr-2" title="Создать" @click="editCard(0)" :disabled="isRefresh" />
        <ButtonStock title="Обновить" @click="runIntegration()" :disabled="isRefresh" />
        <ButtonStock
          :title="'Остановить обновление' + integrationState"
          @click="cancelIntegration()"
          style="display: none"
        />
      </div>
      <div>
        <div class="analytics-source-text">Источник: ITC Market Access Map</div>
        <div class="analytics-source-text" v-if="integrationLatestCompletionDate">
          Дата обновления: {{ $momentFormat(integrationLatestCompletionDate, 'DD.MM.YYYY') }}
        </div>
      </div>
    </div>
    <div class="mb-4">
      <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
    </div>

    <div>
      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Год</th>
              <th>Страна</th>
              <th>Товар</th>
              <th>Код ТНВЭД</th>
              <th>Торговый режим</th>
              <th>Применяемый тариф</th>
              <th>Адвалорный эквивалент</th>
              <th>Документы</th>
            </tr>
            <tr>
              <th class="filter">
                <FilterInput
                  type="year"
                  v-model="filter.year"
                  placeholder="Год"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  type="select2"
                  :select2-options="countries"
                  :select2-settings="select2SettingsMulti"
                  v-model="filter.reporters"
                  multiple
                  placeholder="(Все)"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter" colspan="2">
                <FilterInput
                  v-model="filter.product"
                  placeholder="Код или название ТНВЭД"
                  v-on:valueChangedDebounced="changeFilter"
                />

                <FilterInput
                  id="chkSkipEmptyTnved"
                  v-model="filter.skipEmptyTnved"
                  type="checkbox"
                  label="Не показывать записи без товаров"
                  class="ml-4"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  v-model="filter.tariffRegime"
                  placeholder="Торговый режим"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  v-model="filter.tariffReported"
                  placeholder="Применяемый тариф"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  v-model="filter.tariffAve"
                  placeholder="Адвалорный эквивалент"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  id="chkSkipEmptyDocs"
                  v-model="filter.skipEmptyDocs"
                  type="checkbox"
                  label="Наличие"
                  class="ml-4"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length && !isLoading">
            <tr v-for="item in page.items" :key="item.id">
              <td>{{ item.year }}</td>
              <td>{{ item.countryImporter?.nameFull || item.countryImporter?.name }}</td>
              <td>
                <div class="font-italic">{{ item.tnvedGroup?.name }}</div>
                <div>{{ item.tnved?.name }}</div>
              </td>
              <td>
                {{ item.tnvedGroup?.code }}
              </td>
              <td>{{ item.tariffRegime }}</td>
              <td>{{ item.tariffReported }}</td>
              <td>{{ item.tariffAve }}</td>
              <td>
                <div v-html="getItemNote(item)"></div>
              </td>
              <td>
                <IconComponent @click="editCard(item.id)" name="mode" />
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="7">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="7">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import FilterInput from '../../../common/components/FilterInput';
  import IconComponent from '../../../common/ui/IconComponent';
  import Api from '../api/macmaporgcustomsduties';

  export default {
    name: 'MacmaporgCustomsDuties',
    components: { IconComponent, FilterInput, ButtonStock, DefaultFilter },
    mixins: [filtermanager],
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          year: 2022,
          reporters: [],
          product: null,
          tariffRegime: null,
          tariffReported: null,
          tariffAve: null,
          skipEmptyTnved: true,
          skipEmptyDocs: false,
        },
        countries: [],
        select2SettingsMulti: Constants.select2SettingsMultiple,
        isLoading: false,
        isRefresh: true,
        integrationState: '',
        integrationLatestCompletionDate: null,
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadDictionaries();
        this.loadPage(false);
      });
    },
    methods: {
      loadPage(append) {
        this.isLoading = !append;
        const request = Object.assign(this.filter, this.request);
        Api.search(request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
        Api.getIntegrationState().then((response) => {
          if (response.data) {
            var state = response.data.state;
            this.isRefresh = state && state.finished == null;
            if (this.isRefresh) {
              var countriesDone = state.countriesDone + state.countriesNoData;
              this.integrationState = ` (${countriesDone}/${state.countriesTotal})`;
            }
            this.integrationLatestCompletionDate = response.data.latestCompletionDate;
          }
        });
      },
      loadDictionaries() {
        Utils.searchNsi('countrylistwithdata', {})
          .then((response) => {
            this.countries = response.data
              .filter((x) => x.codeForDuties != null)
              .map((x) => {
                return { text: x.name, id: x.codeForDuties };
              });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      getItemNote(item) {
        if (!item.tariffNote) return '';
        const docs = item.tariffNote.documents;
        if (!docs?.length) return '';
        const docEntry = docs[0];
        let result = [];
        if (docEntry.certificates?.length) {
          docEntry.certificates.forEach((d) => {
            result.push(`<div><a href="${d.url}" target="_blank">${d.title}</a></div>`);
          });
        }
        if (docEntry.rulesOfOrigins?.length) {
          docEntry.rulesOfOrigins.forEach((d) => {
            result.push(`<div><a href="${d.url}" target="_blank">${d.title}</a></div>`);
          });
        }
        return result.join('\n');
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      editCard(id) {
        this.$router.push({ name: 'MacmaporgCustomsDutyCard', params: { id: id, action: 'edit' } });
      },
      runIntegration() {
        Constants.alert.fire(
          'Обновление',
          'Запущен парсинг данных, ориентировочное время выполнения - 10 часов',
          'success',
        );
        this.isRefresh = true;
        Api.runIntegration();
      },
      cancelIntegration() {
        Api.cancelIntegration();
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss">
  .filter {
    .form-label {
      font-size: 14px !important;
    }
  }
</style>
